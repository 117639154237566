import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_setup_ShLTgzFPbc_q1B_mwMWcBFheauHtjpaJ9PIcclMTh3c from "/app/plugins/sentry-setup.ts";
import logger_KcHRkFEb0TtUwTUy_KSIbCS8Ki8q3to0GOPe_rXFA9k from "/app/lib/plugins/logger.ts";
import events_JObHbPxOAxh_SWH86Ctj7focwf7AQHQ8A2SsdGVUc5w from "/app/lib/plugins/events.ts";
import console_logger_FSrDGTCIfFJ0tzNgJ2zGtu2_oggpl_JXMgC2fltjGRk from "/app/lib/plugins/console-logger.ts";
import sentry_logger_nzsp7oCh7SoaSQrYMTDYVkEEGQlf7B6Rb3xm1QqDHxw from "/app/lib/plugins/sentry-logger.ts";
import loginRedirect_dBPVo9jGkW_t6Qs87iN36_rF11SLCebWzMzuM5RdroQ from "/app/plugins/loginRedirect.ts";
import toast_F_pfj7FgRLbXLAWiXjuGtvyN_yqw93f_EjHy5PiyXpc from "/app/plugins/toast.ts";
import notify_K4r6NLr_E3EAp6HuLL2Brgr2Kpn6lq4N1dtvJssN17I from "/app/plugins/notify.ts";
import set_default_locale_hS37yDlvATCTrTnDw0FLrLfAgIo_zhjkxzV7PDn1xCQ from "/app/plugins/set-default-locale.ts";
import appLocale_JMfakDdEGZekW_vGE__fgzu7YrvE1G6wlT9_7zZ_yFk from "/app/plugins/appLocale.ts";
import njordaB2BAuth_DVCAlLKahI6iqxBQBlVa417KtJ5QEwXWUfAAWXLG6Y8 from "/app/plugins/njordaB2BAuth.ts";
import njordaAdminApi_NsbXDZGXcBUGoWRh28QEFcrSumVHiki8_i6G7lyADrM from "/app/plugins/njordaAdminApi.ts";
import njordaEmbedB2BApi_0fe_saFXV3UmhSNqzKPpD67oppI3QgijxBYgoO0FkAg from "/app/plugins/njordaEmbedB2BApi.ts";
import njordaB2BApi_v7R9otzZNOSBjKUhzrtv4fz1mrbv_fZnCcgrkv93pDo from "/app/plugins/njordaB2BApi.ts";
import organization_fj5Ytboy8HTSTpa8AOw_n_hS0PvULT6NTMi9bv8aK_o from "/app/plugins/organization.ts";
import org_permissions_b7lUi1fDRDLxrk2BLSHGO3OawtEJT2475XILOQY8YH8 from "/app/plugins/org-permissions.ts";
import user_role_qFozYnBVzkAKTmS6_H_ZXhNvN7JudyXJGWiIlqEXt0o from "/app/plugins/user-role.ts";
import maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo from "/app/plugins/maska.ts";
import iframe_LFVoOYxY7qjPAC6WlBFZ3r5PhccOYGvsfX_fC84QnH4 from "/app/plugins/iframe.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/app/plugins/vuetify.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  sentry_setup_ShLTgzFPbc_q1B_mwMWcBFheauHtjpaJ9PIcclMTh3c,
  logger_KcHRkFEb0TtUwTUy_KSIbCS8Ki8q3to0GOPe_rXFA9k,
  events_JObHbPxOAxh_SWH86Ctj7focwf7AQHQ8A2SsdGVUc5w,
  console_logger_FSrDGTCIfFJ0tzNgJ2zGtu2_oggpl_JXMgC2fltjGRk,
  sentry_logger_nzsp7oCh7SoaSQrYMTDYVkEEGQlf7B6Rb3xm1QqDHxw,
  loginRedirect_dBPVo9jGkW_t6Qs87iN36_rF11SLCebWzMzuM5RdroQ,
  toast_F_pfj7FgRLbXLAWiXjuGtvyN_yqw93f_EjHy5PiyXpc,
  notify_K4r6NLr_E3EAp6HuLL2Brgr2Kpn6lq4N1dtvJssN17I,
  set_default_locale_hS37yDlvATCTrTnDw0FLrLfAgIo_zhjkxzV7PDn1xCQ,
  appLocale_JMfakDdEGZekW_vGE__fgzu7YrvE1G6wlT9_7zZ_yFk,
  njordaB2BAuth_DVCAlLKahI6iqxBQBlVa417KtJ5QEwXWUfAAWXLG6Y8,
  njordaAdminApi_NsbXDZGXcBUGoWRh28QEFcrSumVHiki8_i6G7lyADrM,
  njordaEmbedB2BApi_0fe_saFXV3UmhSNqzKPpD67oppI3QgijxBYgoO0FkAg,
  njordaB2BApi_v7R9otzZNOSBjKUhzrtv4fz1mrbv_fZnCcgrkv93pDo,
  organization_fj5Ytboy8HTSTpa8AOw_n_hS0PvULT6NTMi9bv8aK_o,
  org_permissions_b7lUi1fDRDLxrk2BLSHGO3OawtEJT2475XILOQY8YH8,
  user_role_qFozYnBVzkAKTmS6_H_ZXhNvN7JudyXJGWiIlqEXt0o,
  maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo,
  iframe_LFVoOYxY7qjPAC6WlBFZ3r5PhccOYGvsfX_fC84QnH4,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]