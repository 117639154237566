import mitt from "~/utils/mitt";

declare module "#app" {
  interface NuxtApp {
    $trigger: (typeof mitt)["prototype"]["emit"];
    $subscribe: (typeof mitt)["prototype"]["subscribe"];
    $unsubscribe: (typeof mitt)["prototype"]["unsubscribe"];
  }
}

export default defineNuxtPlugin(() => {
  const emitter = mitt();

  return {
    provide: {
      trigger: emitter.emit,
      subscribe: emitter.on,
      unsubscribe: emitter.off,
    },
  };
});
