
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutUcooZuBFq6Y_45qkSHe3Slgw7Ozl4rsMv5kY96MDZ8icoMeta } from "/app/pages/logout.vue?macro=true";
import { default as apizI_45RuAh4WyeLAafdGlYthReY6DXRC9DPnvhOSQbjzBkMeta } from "/app/pages/docs/api.vue?macro=true";
import { default as test_45linkssr5KCXq2LZuRIsz6POyhK2kCOe0fi_45cR3Dc05ulXdQYMeta } from "/app/pages/test-links.vue?macro=true";
import { default as indexGi2L3vLyyaPX_jxO9BB4DFLlq8X7HBKqdc99nEmnEhEMeta } from "/app/pages/advisor/index.vue?macro=true";
import { default as embedU9ysfuaRlTepa0qwRh1dhS6cktoWBztbojljWWSvTXUMeta } from "/app/pages/developer/embed.vue?macro=true";
import { default as indexg1m20y1oTJw8j8ywuvqyqmnFzshU4XIKEmWOR1AO3kEMeta } from "/app/pages/developer/index.vue?macro=true";
import { default as forget_45passwordb_9cvKV7RLpfuojGUieffZXS1xNS1q4kTjmk12b9LWAMeta } from "/app/pages/forget-password.vue?macro=true";
import { default as appo1aQKCUnKYbrR2obAa2SyCZcDla2yrpqb0_45EIqvpYsMMeta } from "/app/pages/developer/api/app.vue?macro=true";
import { default as b2bc1xvt5x_45u8KgDb4hWEVtwYjeTV5Q_v3DBSGJ9qoYcyIMeta } from "/app/pages/developer/api/b2b.vue?macro=true";
import { default as admintnjsewHww_k0thxb_45azn4ygvto0z9LPLDRnwqku9SuIMeta } from "/app/pages/developer/api/admin.vue?macro=true";
import { default as user_45listw10woAYZUzZhMLYUMDEoRIqqrHFTJYAGy737OciyJ0UMeta } from "/app/pages/developer/user-list.vue?macro=true";
import { default as b2b_45v20nCz32JTwX5FLml5fPJtGYdn6YB8tMKH_V4MuMogDIMMeta } from "/app/pages/developer/api/b2b-v2.vue?macro=true";
import { default as termsM0j2KGJHXEv134v3UNI6rOjr14zJUF2rbV1oGS1uRDoMeta } from "/app/pages/client/[org_id]/terms.vue?macro=true";
import { default as ai_45run_45evalSAuJxvZJ1HTwyq5fR3N0jknwAdAHRmQlRbDfuttRY3AMeta } from "/app/pages/developer/ai-run-eval.vue?macro=true";
import { default as connectKqcRn2krErMH49_45WhUNd4cZ0hfkpWI_45BVVmq_Hn4vgsMeta } from "/app/pages/developer/api/connect.vue?macro=true";
import { default as _91uuid_93iwSPQO3aAPxhxeqW0iCPOhLS8izMXFMvSYwr62yJpXEMeta } from "/app/pages/reset-password/[uuid].vue?macro=true";
import { default as indexPETSQPYHC17gRgOtrUrHBK2R9tt8nRQQaZUvM_RlNLcMeta } from "/app/pages/advisor/[org_id]/index.vue?macro=true";
import { default as loginKqi5kQMNa7EQL3RDZDLa2_45fwX0EBsnJjblDleidZrx4Meta } from "/app/pages/advisor/[org_id]/login.vue?macro=true";
import { default as trigger_45taskrD_Kz0hfq6WesSCeqraJuZeZIEPKUK3nCAbv2UhWGL4Meta } from "/app/pages/developer/trigger-task.vue?macro=true";
import { default as user_45detailsR0A7bPttd0zgdUw0fOKORAYmPNUjN_Fb0p649jkhoeUMeta } from "/app/pages/developer/user-details.vue?macro=true";
import { default as data_45sessionsgqPGgFG_POZ4JT0b5MwjNw6WXtN6Wm1vDz4OQTQU6qcMeta } from "/app/pages/developer/data-sessions.vue?macro=true";
import { default as organizationsdNTdBS65PdRTRaCDdvS_f9X9dbAdVSb1_cMJGQ46onMMeta } from "/app/pages/developer/organizations.vue?macro=true";
import { default as client_45detailssdCxhG41xfReqEjv6Oyv4ekYgNLa86o5kOG86qc2rkcMeta } from "/app/pages/developer/client-details.vue?macro=true";
import { default as portfolio_45dataZs7kNJBq4G2ApOAbIHQtxoEP9kF2Li_CAbbLjWJMx9EMeta } from "/app/pages/developer/portfolio-data.vue?macro=true";
import { default as holdings_45reportW7Gh_45ZOJKksGpQYy7ANfxyL7Dj9fQSjXqIjBAVA7ED0Meta } from "/app/pages/developer/holdings-report.vue?macro=true";
import { default as instrument_45listVe_45Sn34p6pierUmkPajxR55Jy96WrMWoySzTCACNJC4Meta } from "/app/pages/developer/instrument-list.vue?macro=true";
import { default as report_45donee8wrzchQTRjAAam8ckH_XLqbdgcpYsTsZQre82oEUJYMeta } from "/app/pages/client/[org_id]/report-done.vue?macro=true";
import { default as component_45galleryI6DO33U084hb3a61D_HDLKFaXdcZXKmI943vVxEwSxwMeta } from "/app/pages/developer/component-gallery.vue?macro=true";
import { default as instrument_45issuesFJ8he6VVzqidzc48GgufC0QHqXekJ8inkmKkqDQGv84Meta } from "/app/pages/developer/instrument-issues.vue?macro=true";
import { default as integration_45testsIVQ36XIHNg0MMgHffmqVuKFUrGerGamSQcQT9JfzLWoMeta } from "/app/pages/developer/integration-tests.vue?macro=true";
import { default as new9YsgAXjX8fYFcDrnHeo_45hD511ZaimURnX6536tn7lRUMeta } from "/app/pages/advisor/[org_id]/clients/new.vue?macro=true";
import { default as instrument_45detailsWevWOzEU34V2ermhTcVQBu9BaoL7lUjJZQXdXtwg_45doMeta } from "/app/pages/developer/instrument-details.vue?macro=true";
import { default as bad_45orgyb50kfe3UUSejIWAroda6LewzIs3EXzbqyhKyxsXOzwMeta } from "/app/pages/client/[org_id]/error/bad-org.vue?macro=true";
import { default as indexlgkeSfN8iS6728xYtywZzrr7R2inIQCBqw_pzejPLR0Meta } from "/app/pages/advisor/[org_id]/clients/index.vue?macro=true";
import { default as indexO_QddkG3_xfwbNU3jFfiUG_45vTsPFJ13GkO6f1_45cTmbEMeta } from "/app/pages/advisor/[org_id]/analytics/index.vue?macro=true";
import { default as newIFf94epBBnpR2dWg4kSBF8_dyGcdqUNu_4526dp60otVkMeta } from "/app/pages/advisor/[org_id]/manage/users/new.vue?macro=true";
import { default as app4hLgFUvJtjadODSg7Nu0zCwOih6E8Ku_450qVYqJm_45zi4Meta } from "/app/pages/advisor/[org_id]/manage/njorda/app.vue?macro=true";
import { default as integration_45tests_45reportICk6g88ZFDGXknsuhpkV_H3v_xZjIqoSQR2QHxuZWtsMeta } from "/app/pages/developer/integration-tests-report.vue?macro=true";
import { default as indexqjvHarfzfkgkZMsl4m4IUOz1G3nPn7yeKg2M3tp_XlkMeta } from "/app/pages/advisor/[org_id]/manage/users/index.vue?macro=true";
import { default as bad_45org_OHzGwXQ_454Zgu51PqCBnSGf6iWRQwpplmnD6zTa9yXcMeta } from "/app/pages/product-demo/[org_id]/error/bad-org.vue?macro=true";
import { default as indexcjahsMmbXr0sprC_45At3hNlMcYvMAOTS3MofRPfv89uQMeta } from "/app/pages/advisor/[org_id]/manage/njorda/index.vue?macro=true";
import { default as terms_45and_45conditionsAPb0P9sy73iad8UD9O6lf8jmpO5aBYyKeukiNi5Yb4oMeta } from "/app/pages/client/[org_id]/terms-and-conditions.vue?macro=true";
import { default as _91id_93Sy7N1juFrhpY5LobanxcmJ0JfqzX4nk1IAjEmp8GG4EMeta } from "/app/pages/product-demo/[org_id]/mb/report/[id].vue?macro=true";
import { default as _91id_93rbSI54NrJ23N4jQyBPgFjI9mn27XoRltPf3AFHG4oGcMeta } from "/app/pages/product-demo/[org_id]/mb/review/[id].vue?macro=true";
import { default as _91id_93uk4yITi2ctPUT3kojm0hDp1BwIJ9vd7LNFAiUz0F5wQMeta } from "/app/pages/client/[org_id]/portfolio-report/[id].vue?macro=true";
import { default as newadmCIMm_45F7a3ko_C6nPPKRe7qyWOgzdz4BmbTSkKZYoMeta } from "/app/pages/advisor/[org_id]/analytics/reports/new.vue?macro=true";
import { default as indexaUS5eYgz9DNyPhR__ADHybQ2OjjOpUpd6gjbO2p1J8UMeta } from "/app/pages/advisor/[org_id]/manage/settings/index.vue?macro=true";
import { default as newyL6SpIFqvmfN28d212NwvZRqa3liN1UipoQtyKv_451KMMeta } from "/app/pages/advisor/[org_id]/portfolio-builder/new.vue?macro=true";
import { default as indexJpJaMPZ5rjh2W9TalwuV0nhS4grrRXN3tPaOVrKMi04Meta } from "/app/pages/advisor/[org_id]/analytics/reports/index.vue?macro=true";
import { default as indexRTAcMvoK_45MO1T_nMLNMM6qV_2lhPrkl_45RN6UoLfoVMEMeta } from "/app/pages/advisor/[org_id]/manage/benchmarks/index.vue?macro=true";
import { default as indexWWB0jTrFj1oaGRiGPBs9E4aIcyUabe06DNWPZ3x2bVgMeta } from "/app/pages/advisor/[org_id]/portfolio-builder/index.vue?macro=true";
import { default as editz4XOrgy13BpKmIJ72MSjsAsEr0EBOT0ktuX4N0LW30sMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/edit.vue?macro=true";
import { default as start_45holdings_45collectioniXUPo3rY8ATmnncozo9PRruwkUhZsp0zEiLnUeR5uNwMeta } from "/app/pages/client/[org_id]/start-holdings-collection.vue?macro=true";
import { default as indexZtrg5pErLVNqTQuorz8GglubKDhHOWGpxwZysZ0Z2gEMeta } from "/app/pages/product-demo/[org_id]/sp/investment/index.vue?macro=true";
import { default as indexEs5WsUiW5HNhybqr9Fc7KzECQHNP8kTBP6H22FSUOgsMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/index.vue?macro=true";
import { default as indextWeX5nLayYTLfHFnOhNdEQQsgjNvzDbV0G2KQU314_IMeta } from "/app/pages/advisor/[org_id]/manage/calculations/index.vue?macro=true";
import { default as indexw_45mK_V6A48mBWDYmqAa9YIMJOesZNswU6cfxxiJ_jTYMeta } from "/app/pages/advisor/[org_id]/manage/integrations/index.vue?macro=true";
import { default as indexBUSNMheG9V1Ayrq7pATaQ09NUceL3IY8wCXb2dzBUAcMeta } from "/app/pages/advisor/[org_id]/manage/asset-classes/index.vue?macro=true";
import { default as indexC_gXZmbQ_45htomUByWtv_455MZHoCESBniQ3s8HHgmKxQkMeta } from "/app/pages/advisor/[org_id]/manage/product-lists/index.vue?macro=true";
import { default as accountryuOg1kZLPdmNRFiN8GQs8MEcJJvpbLT0p8zVXBUm7IMeta } from "/app/pages/product-demo/[org_id]/sp/investment/account.vue?macro=true";
import { default as editK9Nzt_45MUSOzeWULcMPJ7YYkZJ0ybhWhJh1LM1esvmpQMeta } from "/app/pages/advisor/[org_id]/manage/users/[user_id]/edit.vue?macro=true";
import { default as _91id_93AZdnFxuvDChEezGGG3FxA8RulguYFRX9J_j_VsjnYloMeta } from "/app/pages/client/[org_id]/portfolio-sharing/start/[id].vue?macro=true";
import { default as pdfHsz_CvdAkg5Sce7PAlV_8vY8cYdm9zRHRFAQVr3kWCIMeta } from "/app/pages/embed/advisor/[org_id]/portfolio-builder/pdf.vue?macro=true";
import { default as overview9AcTMhl1hVii7veJlLpZ6PfwdBu8DTuR_45bXTuaanMyMMeta } from "/app/pages/product-demo/[org_id]/sp/investment/overview.vue?macro=true";
import { default as positionmT_45BMgmjwxyOzjJHei61yGOqzJMVvJzBkD702cC0XIMMeta } from "/app/pages/product-demo/[org_id]/sp/investment/position.vue?macro=true";
import { default as indexBhLdVQqw1ZaXNgvFFYjRzJq2rxXYt8Xj6RPz_45fmnPIMMeta } from "/app/pages/advisor/[org_id]/manage/users/[user_id]/index.vue?macro=true";
import { default as _91id_93hYZAgBgrz2EbXyjIcxIvp8rLrcoBsiRqEklzAhJGsS4Meta } from "/app/pages/client/[org_id]/portfolio-sharing/step-1/[id].vue?macro=true";
import { default as _91id_93dFHNWmL4U_458PVVf7ZFjG_zHmVEaXdvlnHfkQChRHR5MMeta } from "/app/pages/client/[org_id]/portfolio-sharing/step-2/[id].vue?macro=true";
import { default as _91report_id_93I6pZ0UilrvJcZQyKh1GBcVH9PlUhBbGEbojRkoeowi8Meta } from "/app/pages/advisor/[org_id]/analytics/reports/[report_id].vue?macro=true";
import { default as index0uNbLfFf1VSSInDK7Tl9tSpYRBUHKDqqcr2OT_45lnldsMeta } from "/app/pages/embed/advisor/[org_id]/portfolio-builder/index.vue?macro=true";
import { default as indexDko0EK3FBp0NvLj1A2KxbIufrQSxiKzhqf9cl2JvwmgMeta } from "/app/pages/advisor/[org_id]/manage/asset-class-rules/index.vue?macro=true";
import { default as _91proposal_id_93wHCwHtu5OM2polPxHGGd4qy8l50x9j2pZhdoorYyQYUMeta } from "/app/pages/advisor/[org_id]/portfolio-builder/[proposal_id].vue?macro=true";
import { default as index3I1DlwExiFDyn21vTlsItpAeU7dRtpvjwN_45wxpn6BxAMeta } from "/app/pages/advisor/[org_id]/manage/portfolio-templates/index.vue?macro=true";
import { default as indextNcPDVgKjn_8vqGuWC4T0690OuCmhkq1NaNyHwcVe4gMeta } from "/app/pages/advisor/[org_id]/manage/product-list-allocations/index.vue?macro=true";
import { default as _91report_id_93emYVioGvZylNmnFBJWlgwK0Fav4ctaJm2xz5vY6pJ_454Meta } from "/app/pages/advisor/[org_id]/clients/[client_id]/report/[report_id].vue?macro=true";
import { default as indexQ5wbBnyrv_vEZLAsXlIh0Ec7txr749n9X34dAwBEJa8Meta } from "/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/index.vue?macro=true";
import { default as _91position_id_934k4F9deitCaF8vnnF1ATrs7jivnYyjiTXbrGZMUO1ssMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/position/[position_id].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "docs-api",
    path: "/docs/api",
    meta: apizI_45RuAh4WyeLAafdGlYthReY6DXRC9DPnvhOSQbjzBkMeta || {},
    component: () => import("/app/pages/docs/api.vue")
  },
  {
    name: "test-links",
    path: "/test-links",
    meta: test_45linkssr5KCXq2LZuRIsz6POyhK2kCOe0fi_45cR3Dc05ulXdQYMeta || {},
    component: () => import("/app/pages/test-links.vue")
  },
  {
    name: "advisor",
    path: "/advisor",
    meta: indexGi2L3vLyyaPX_jxO9BB4DFLlq8X7HBKqdc99nEmnEhEMeta || {},
    component: () => import("/app/pages/advisor/index.vue")
  },
  {
    name: "developer-embed",
    path: "/developer/embed",
    meta: embedU9ysfuaRlTepa0qwRh1dhS6cktoWBztbojljWWSvTXUMeta || {},
    component: () => import("/app/pages/developer/embed.vue")
  },
  {
    name: "developer",
    path: "/developer",
    meta: indexg1m20y1oTJw8j8ywuvqyqmnFzshU4XIKEmWOR1AO3kEMeta || {},
    component: () => import("/app/pages/developer/index.vue")
  },
  {
    name: "forget-password",
    path: "/forget-password",
    meta: forget_45passwordb_9cvKV7RLpfuojGUieffZXS1xNS1q4kTjmk12b9LWAMeta || {},
    component: () => import("/app/pages/forget-password.vue")
  },
  {
    name: "developer-api-app",
    path: "/developer/api/app",
    meta: appo1aQKCUnKYbrR2obAa2SyCZcDla2yrpqb0_45EIqvpYsMMeta || {},
    component: () => import("/app/pages/developer/api/app.vue")
  },
  {
    name: "developer-api-b2b",
    path: "/developer/api/b2b",
    meta: b2bc1xvt5x_45u8KgDb4hWEVtwYjeTV5Q_v3DBSGJ9qoYcyIMeta || {},
    component: () => import("/app/pages/developer/api/b2b.vue")
  },
  {
    name: "developer-api-admin",
    path: "/developer/api/admin",
    meta: admintnjsewHww_k0thxb_45azn4ygvto0z9LPLDRnwqku9SuIMeta || {},
    component: () => import("/app/pages/developer/api/admin.vue")
  },
  {
    name: "developer-user-list",
    path: "/developer/user-list",
    meta: user_45listw10woAYZUzZhMLYUMDEoRIqqrHFTJYAGy737OciyJ0UMeta || {},
    component: () => import("/app/pages/developer/user-list.vue")
  },
  {
    name: "developer-api-b2b-v2",
    path: "/developer/api/b2b-v2",
    meta: b2b_45v20nCz32JTwX5FLml5fPJtGYdn6YB8tMKH_V4MuMogDIMMeta || {},
    component: () => import("/app/pages/developer/api/b2b-v2.vue")
  },
  {
    name: "client-org_id-terms",
    path: "/client/:org_id()/terms",
    meta: termsM0j2KGJHXEv134v3UNI6rOjr14zJUF2rbV1oGS1uRDoMeta || {},
    component: () => import("/app/pages/client/[org_id]/terms.vue")
  },
  {
    name: "developer-ai-run-eval",
    path: "/developer/ai-run-eval",
    meta: ai_45run_45evalSAuJxvZJ1HTwyq5fR3N0jknwAdAHRmQlRbDfuttRY3AMeta || {},
    component: () => import("/app/pages/developer/ai-run-eval.vue")
  },
  {
    name: "developer-api-connect",
    path: "/developer/api/connect",
    meta: connectKqcRn2krErMH49_45WhUNd4cZ0hfkpWI_45BVVmq_Hn4vgsMeta || {},
    component: () => import("/app/pages/developer/api/connect.vue")
  },
  {
    name: "reset-password-uuid",
    path: "/reset-password/:uuid()",
    meta: _91uuid_93iwSPQO3aAPxhxeqW0iCPOhLS8izMXFMvSYwr62yJpXEMeta || {},
    component: () => import("/app/pages/reset-password/[uuid].vue")
  },
  {
    name: "advisor-org_id",
    path: "/advisor/:org_id()",
    meta: indexPETSQPYHC17gRgOtrUrHBK2R9tt8nRQQaZUvM_RlNLcMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/index.vue")
  },
  {
    name: "advisor-org_id-login",
    path: "/advisor/:org_id()/login",
    meta: loginKqi5kQMNa7EQL3RDZDLa2_45fwX0EBsnJjblDleidZrx4Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/login.vue")
  },
  {
    name: "developer-trigger-task",
    path: "/developer/trigger-task",
    meta: trigger_45taskrD_Kz0hfq6WesSCeqraJuZeZIEPKUK3nCAbv2UhWGL4Meta || {},
    component: () => import("/app/pages/developer/trigger-task.vue")
  },
  {
    name: "developer-user-details",
    path: "/developer/user-details",
    meta: user_45detailsR0A7bPttd0zgdUw0fOKORAYmPNUjN_Fb0p649jkhoeUMeta || {},
    component: () => import("/app/pages/developer/user-details.vue")
  },
  {
    name: "developer-data-sessions",
    path: "/developer/data-sessions",
    meta: data_45sessionsgqPGgFG_POZ4JT0b5MwjNw6WXtN6Wm1vDz4OQTQU6qcMeta || {},
    component: () => import("/app/pages/developer/data-sessions.vue")
  },
  {
    name: "developer-organizations",
    path: "/developer/organizations",
    meta: organizationsdNTdBS65PdRTRaCDdvS_f9X9dbAdVSb1_cMJGQ46onMMeta || {},
    component: () => import("/app/pages/developer/organizations.vue")
  },
  {
    name: "developer-client-details",
    path: "/developer/client-details",
    meta: client_45detailssdCxhG41xfReqEjv6Oyv4ekYgNLa86o5kOG86qc2rkcMeta || {},
    component: () => import("/app/pages/developer/client-details.vue")
  },
  {
    name: "developer-portfolio-data",
    path: "/developer/portfolio-data",
    meta: portfolio_45dataZs7kNJBq4G2ApOAbIHQtxoEP9kF2Li_CAbbLjWJMx9EMeta || {},
    component: () => import("/app/pages/developer/portfolio-data.vue")
  },
  {
    name: "developer-holdings-report",
    path: "/developer/holdings-report",
    meta: holdings_45reportW7Gh_45ZOJKksGpQYy7ANfxyL7Dj9fQSjXqIjBAVA7ED0Meta || {},
    component: () => import("/app/pages/developer/holdings-report.vue")
  },
  {
    name: "developer-instrument-list",
    path: "/developer/instrument-list",
    meta: instrument_45listVe_45Sn34p6pierUmkPajxR55Jy96WrMWoySzTCACNJC4Meta || {},
    component: () => import("/app/pages/developer/instrument-list.vue")
  },
  {
    name: "client-org_id-report-done",
    path: "/client/:org_id()/report-done",
    meta: report_45donee8wrzchQTRjAAam8ckH_XLqbdgcpYsTsZQre82oEUJYMeta || {},
    component: () => import("/app/pages/client/[org_id]/report-done.vue")
  },
  {
    name: "developer-component-gallery",
    path: "/developer/component-gallery",
    meta: component_45galleryI6DO33U084hb3a61D_HDLKFaXdcZXKmI943vVxEwSxwMeta || {},
    component: () => import("/app/pages/developer/component-gallery.vue")
  },
  {
    name: "developer-instrument-issues",
    path: "/developer/instrument-issues",
    meta: instrument_45issuesFJ8he6VVzqidzc48GgufC0QHqXekJ8inkmKkqDQGv84Meta || {},
    component: () => import("/app/pages/developer/instrument-issues.vue")
  },
  {
    name: "developer-integration-tests",
    path: "/developer/integration-tests",
    meta: integration_45testsIVQ36XIHNg0MMgHffmqVuKFUrGerGamSQcQT9JfzLWoMeta || {},
    component: () => import("/app/pages/developer/integration-tests.vue")
  },
  {
    name: "advisor-org_id-clients-new",
    path: "/advisor/:org_id()/clients/new",
    meta: new9YsgAXjX8fYFcDrnHeo_45hD511ZaimURnX6536tn7lRUMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/new.vue")
  },
  {
    name: "developer-instrument-details",
    path: "/developer/instrument-details",
    meta: instrument_45detailsWevWOzEU34V2ermhTcVQBu9BaoL7lUjJZQXdXtwg_45doMeta || {},
    component: () => import("/app/pages/developer/instrument-details.vue")
  },
  {
    name: "client-org_id-error-bad-org",
    path: "/client/:org_id()/error/bad-org",
    meta: bad_45orgyb50kfe3UUSejIWAroda6LewzIs3EXzbqyhKyxsXOzwMeta || {},
    component: () => import("/app/pages/client/[org_id]/error/bad-org.vue")
  },
  {
    name: "advisor-org_id-clients",
    path: "/advisor/:org_id()/clients",
    meta: indexlgkeSfN8iS6728xYtywZzrr7R2inIQCBqw_pzejPLR0Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/index.vue")
  },
  {
    name: "advisor-org_id-analytics",
    path: "/advisor/:org_id()/analytics",
    meta: indexO_QddkG3_xfwbNU3jFfiUG_45vTsPFJ13GkO6f1_45cTmbEMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/index.vue")
  },
  {
    name: "advisor-org_id-manage-users-new",
    path: "/advisor/:org_id()/manage/users/new",
    meta: newIFf94epBBnpR2dWg4kSBF8_dyGcdqUNu_4526dp60otVkMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/new.vue")
  },
  {
    name: "advisor-org_id-manage-njorda-app",
    path: "/advisor/:org_id()/manage/njorda/app",
    meta: app4hLgFUvJtjadODSg7Nu0zCwOih6E8Ku_450qVYqJm_45zi4Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/njorda/app.vue")
  },
  {
    name: "developer-integration-tests-report",
    path: "/developer/integration-tests-report",
    meta: integration_45tests_45reportICk6g88ZFDGXknsuhpkV_H3v_xZjIqoSQR2QHxuZWtsMeta || {},
    component: () => import("/app/pages/developer/integration-tests-report.vue")
  },
  {
    name: "advisor-org_id-manage-users",
    path: "/advisor/:org_id()/manage/users",
    meta: indexqjvHarfzfkgkZMsl4m4IUOz1G3nPn7yeKg2M3tp_XlkMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/index.vue")
  },
  {
    name: "product-demo-org_id-error-bad-org",
    path: "/product-demo/:org_id()/error/bad-org",
    meta: bad_45org_OHzGwXQ_454Zgu51PqCBnSGf6iWRQwpplmnD6zTa9yXcMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/error/bad-org.vue")
  },
  {
    name: "advisor-org_id-manage-njorda",
    path: "/advisor/:org_id()/manage/njorda",
    meta: indexcjahsMmbXr0sprC_45At3hNlMcYvMAOTS3MofRPfv89uQMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/njorda/index.vue")
  },
  {
    name: "client-org_id-terms-and-conditions",
    path: "/client/:org_id()/terms-and-conditions",
    meta: terms_45and_45conditionsAPb0P9sy73iad8UD9O6lf8jmpO5aBYyKeukiNi5Yb4oMeta || {},
    component: () => import("/app/pages/client/[org_id]/terms-and-conditions.vue")
  },
  {
    name: "product-demo-org_id-mb-report-id",
    path: "/product-demo/:org_id()/mb/report/:id()",
    meta: _91id_93Sy7N1juFrhpY5LobanxcmJ0JfqzX4nk1IAjEmp8GG4EMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/mb/report/[id].vue")
  },
  {
    name: "product-demo-org_id-mb-review-id",
    path: "/product-demo/:org_id()/mb/review/:id()",
    meta: _91id_93rbSI54NrJ23N4jQyBPgFjI9mn27XoRltPf3AFHG4oGcMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/mb/review/[id].vue")
  },
  {
    name: "client-org_id-portfolio-report-id",
    path: "/client/:org_id()/portfolio-report/:id()",
    meta: _91id_93uk4yITi2ctPUT3kojm0hDp1BwIJ9vd7LNFAiUz0F5wQMeta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-report/[id].vue")
  },
  {
    name: "advisor-org_id-analytics-reports-new",
    path: "/advisor/:org_id()/analytics/reports/new",
    meta: newadmCIMm_45F7a3ko_C6nPPKRe7qyWOgzdz4BmbTSkKZYoMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/reports/new.vue")
  },
  {
    name: "advisor-org_id-manage-settings",
    path: "/advisor/:org_id()/manage/settings",
    meta: indexaUS5eYgz9DNyPhR__ADHybQ2OjjOpUpd6gjbO2p1J8UMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/settings/index.vue")
  },
  {
    name: "advisor-org_id-portfolio-builder-new",
    path: "/advisor/:org_id()/portfolio-builder/new",
    meta: newyL6SpIFqvmfN28d212NwvZRqa3liN1UipoQtyKv_451KMMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/portfolio-builder/new.vue")
  },
  {
    name: "advisor-org_id-analytics-reports",
    path: "/advisor/:org_id()/analytics/reports",
    meta: indexJpJaMPZ5rjh2W9TalwuV0nhS4grrRXN3tPaOVrKMi04Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/reports/index.vue")
  },
  {
    name: "advisor-org_id-manage-benchmarks",
    path: "/advisor/:org_id()/manage/benchmarks",
    meta: indexRTAcMvoK_45MO1T_nMLNMM6qV_2lhPrkl_45RN6UoLfoVMEMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/benchmarks/index.vue")
  },
  {
    name: "advisor-org_id-portfolio-builder",
    path: "/advisor/:org_id()/portfolio-builder",
    meta: indexWWB0jTrFj1oaGRiGPBs9E4aIcyUabe06DNWPZ3x2bVgMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/portfolio-builder/index.vue")
  },
  {
    name: "advisor-org_id-clients-client_id-edit",
    path: "/advisor/:org_id()/clients/:client_id()/edit",
    meta: editz4XOrgy13BpKmIJ72MSjsAsEr0EBOT0ktuX4N0LW30sMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/edit.vue")
  },
  {
    name: "client-org_id-start-holdings-collection",
    path: "/client/:org_id()/start-holdings-collection",
    meta: start_45holdings_45collectioniXUPo3rY8ATmnncozo9PRruwkUhZsp0zEiLnUeR5uNwMeta || {},
    component: () => import("/app/pages/client/[org_id]/start-holdings-collection.vue")
  },
  {
    name: "product-demo-org_id-sp-investment",
    path: "/product-demo/:org_id()/sp/investment",
    meta: indexZtrg5pErLVNqTQuorz8GglubKDhHOWGpxwZysZ0Z2gEMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/index.vue")
  },
  {
    name: "advisor-org_id-clients-client_id",
    path: "/advisor/:org_id()/clients/:client_id()",
    meta: indexEs5WsUiW5HNhybqr9Fc7KzECQHNP8kTBP6H22FSUOgsMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/index.vue")
  },
  {
    name: "advisor-org_id-manage-calculations",
    path: "/advisor/:org_id()/manage/calculations",
    meta: indextWeX5nLayYTLfHFnOhNdEQQsgjNvzDbV0G2KQU314_IMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/calculations/index.vue")
  },
  {
    name: "advisor-org_id-manage-integrations",
    path: "/advisor/:org_id()/manage/integrations",
    meta: indexw_45mK_V6A48mBWDYmqAa9YIMJOesZNswU6cfxxiJ_jTYMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/integrations/index.vue")
  },
  {
    name: "advisor-org_id-manage-asset-classes",
    path: "/advisor/:org_id()/manage/asset-classes",
    meta: indexBUSNMheG9V1Ayrq7pATaQ09NUceL3IY8wCXb2dzBUAcMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/asset-classes/index.vue")
  },
  {
    name: "advisor-org_id-manage-product-lists",
    path: "/advisor/:org_id()/manage/product-lists",
    meta: indexC_gXZmbQ_45htomUByWtv_455MZHoCESBniQ3s8HHgmKxQkMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/product-lists/index.vue")
  },
  {
    name: "product-demo-org_id-sp-investment-account",
    path: "/product-demo/:org_id()/sp/investment/account",
    meta: accountryuOg1kZLPdmNRFiN8GQs8MEcJJvpbLT0p8zVXBUm7IMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/account.vue")
  },
  {
    name: "advisor-org_id-manage-users-user_id-edit",
    path: "/advisor/:org_id()/manage/users/:user_id()/edit",
    meta: editK9Nzt_45MUSOzeWULcMPJ7YYkZJ0ybhWhJh1LM1esvmpQMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/[user_id]/edit.vue")
  },
  {
    name: "client-org_id-portfolio-sharing-start-id",
    path: "/client/:org_id()/portfolio-sharing/start/:id()",
    meta: _91id_93AZdnFxuvDChEezGGG3FxA8RulguYFRX9J_j_VsjnYloMeta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-sharing/start/[id].vue")
  },
  {
    name: "embed-advisor-org_id-portfolio-builder-pdf",
    path: "/embed/advisor/:org_id()/portfolio-builder/pdf",
    meta: pdfHsz_CvdAkg5Sce7PAlV_8vY8cYdm9zRHRFAQVr3kWCIMeta || {},
    component: () => import("/app/pages/embed/advisor/[org_id]/portfolio-builder/pdf.vue")
  },
  {
    name: "product-demo-org_id-sp-investment-overview",
    path: "/product-demo/:org_id()/sp/investment/overview",
    meta: overview9AcTMhl1hVii7veJlLpZ6PfwdBu8DTuR_45bXTuaanMyMMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/overview.vue")
  },
  {
    name: "product-demo-org_id-sp-investment-position",
    path: "/product-demo/:org_id()/sp/investment/position",
    meta: positionmT_45BMgmjwxyOzjJHei61yGOqzJMVvJzBkD702cC0XIMMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/position.vue")
  },
  {
    name: "advisor-org_id-manage-users-user_id",
    path: "/advisor/:org_id()/manage/users/:user_id()",
    meta: indexBhLdVQqw1ZaXNgvFFYjRzJq2rxXYt8Xj6RPz_45fmnPIMMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/[user_id]/index.vue")
  },
  {
    name: "client-org_id-portfolio-sharing-step-1-id",
    path: "/client/:org_id()/portfolio-sharing/step-1/:id()",
    meta: _91id_93hYZAgBgrz2EbXyjIcxIvp8rLrcoBsiRqEklzAhJGsS4Meta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-sharing/step-1/[id].vue")
  },
  {
    name: "client-org_id-portfolio-sharing-step-2-id",
    path: "/client/:org_id()/portfolio-sharing/step-2/:id()",
    meta: _91id_93dFHNWmL4U_458PVVf7ZFjG_zHmVEaXdvlnHfkQChRHR5MMeta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-sharing/step-2/[id].vue")
  },
  {
    name: "advisor-org_id-analytics-reports-report_id",
    path: "/advisor/:org_id()/analytics/reports/:report_id()",
    meta: _91report_id_93I6pZ0UilrvJcZQyKh1GBcVH9PlUhBbGEbojRkoeowi8Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/reports/[report_id].vue")
  },
  {
    name: "embed-advisor-org_id-portfolio-builder",
    path: "/embed/advisor/:org_id()/portfolio-builder",
    meta: index0uNbLfFf1VSSInDK7Tl9tSpYRBUHKDqqcr2OT_45lnldsMeta || {},
    component: () => import("/app/pages/embed/advisor/[org_id]/portfolio-builder/index.vue")
  },
  {
    name: "advisor-org_id-manage-asset-class-rules",
    path: "/advisor/:org_id()/manage/asset-class-rules",
    meta: indexDko0EK3FBp0NvLj1A2KxbIufrQSxiKzhqf9cl2JvwmgMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/asset-class-rules/index.vue")
  },
  {
    name: "advisor-org_id-portfolio-builder-proposal_id",
    path: "/advisor/:org_id()/portfolio-builder/:proposal_id()",
    meta: _91proposal_id_93wHCwHtu5OM2polPxHGGd4qy8l50x9j2pZhdoorYyQYUMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/portfolio-builder/[proposal_id].vue")
  },
  {
    name: "advisor-org_id-manage-portfolio-templates",
    path: "/advisor/:org_id()/manage/portfolio-templates",
    meta: index3I1DlwExiFDyn21vTlsItpAeU7dRtpvjwN_45wxpn6BxAMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/portfolio-templates/index.vue")
  },
  {
    name: "advisor-org_id-manage-product-list-allocations",
    path: "/advisor/:org_id()/manage/product-list-allocations",
    meta: indextNcPDVgKjn_8vqGuWC4T0690OuCmhkq1NaNyHwcVe4gMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/product-list-allocations/index.vue")
  },
  {
    name: "advisor-org_id-clients-client_id-report-report_id",
    path: "/advisor/:org_id()/clients/:client_id()/report/:report_id()",
    meta: _91report_id_93emYVioGvZylNmnFBJWlgwK0Fav4ctaJm2xz5vY6pJ_454Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/report/[report_id].vue")
  },
  {
    name: "advisor-org_id-clients-client_id-account-account_id",
    path: "/advisor/:org_id()/clients/:client_id()/account/:account_id()",
    meta: indexQ5wbBnyrv_vEZLAsXlIh0Ec7txr749n9X34dAwBEJa8Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/index.vue")
  },
  {
    name: "advisor-org_id-clients-client_id-account-account_id-position-position_id",
    path: "/advisor/:org_id()/clients/:client_id()/account/:account_id()/position/:position_id()",
    meta: _91position_id_934k4F9deitCaF8vnnF1ATrs7jivnYyjiTXbrGZMUO1ssMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/position/[position_id].vue")
  }
]