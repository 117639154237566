/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FilterItem = {
    column_id: string;
    id: FilterItem.id;
    value?: any;
};
export namespace FilterItem {
    export enum id {
        EQ = 'eq',
        LT = 'lt',
        GT = 'gt',
        CONTAIN = 'contain',
        EMPTY = 'empty',
        NOT_EMPTY = 'not-empty',
        ERROR = 'error',
        NOT_ERROR = 'not-error',
        IN = 'in',
        NOT_IN = 'not_in',
    }
}

