
// @ts-nocheck
import locale_en_46json_3d74f740 from "#nuxt-i18n/3d74f740";
import locale_en_46json_0b63539c from "#nuxt-i18n/0b63539c";
import locale_sv_46json_f161946b from "#nuxt-i18n/f161946b";
import locale_sv_46json_15050801 from "#nuxt-i18n/15050801";

export const localeCodes =  [
  "en",
  "sv"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46json_3d74f740",
      load: () => Promise.resolve(locale_en_46json_3d74f740),
      cache: true
    },
    {
      key: "locale_en_46json_0b63539c",
      load: () => Promise.resolve(locale_en_46json_0b63539c),
      cache: true
    }
  ],
  sv: [
    {
      key: "locale_sv_46json_f161946b",
      load: () => Promise.resolve(locale_sv_46json_f161946b),
      cache: true
    },
    {
      key: "locale_sv_46json_15050801",
      load: () => Promise.resolve(locale_sv_46json_15050801),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      name: "English",
      code: "en",
      language: "en-US",
      files: [
        "/app/lib/i18n/en.json",
        "/app/i18n/locales/en.json"
      ]
    },
    {
      name: "Swedish",
      code: "sv",
      language: "sv-SE",
      files: [
        "/app/lib/i18n/sv.json",
        "/app/i18n/locales/sv.json"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    name: "English",
    code: "en",
    language: "en-US",
    files: [
      {
        path: "/app/lib/i18n/en.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    name: "Swedish",
    code: "sv",
    language: "sv-SE",
    files: [
      {
        path: "/app/lib/i18n/sv.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/sv.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/